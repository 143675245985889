import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';

import linemanGroup from 'src/assets/linemanGroup.svg';
import { loadUsersFavorites } from 'src/redux/users/actions';
import { selectUsers, selectUsersError, selectUsersFavoriteLoading } from 'src/redux/users/selectors';
import { selectUserFavoriteSuccess } from 'src/redux/user/selectors';
import { H1, Loader, P1R, SomethingWentWrongState, Toolbar, UsersTable } from 'src/components';
import { usersColumnVisibilityModel } from 'src/components/UsersTable';
import config from 'src/config';
import { PermissionEnum } from 'src/shared/enums/permission.enum';

import { useTranslation } from 'react-i18next';
import { DashboardWrapper } from '../wrappers';

export default function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);
  const userFavoriteSuccess = useSelector(selectUserFavoriteSuccess);
  const isUsersLoading = useSelector(selectUsersFavoriteLoading);
  const isUsersLoadingError = useSelector(selectUsersError);

  useEffect(() => {
    dispatch(loadUsersFavorites.init({ favorites: true }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUsersFavorites.init({ favorites: true }));
  }, [dispatch, userFavoriteSuccess]);

  const columnsVisibility = {
    ...usersColumnVisibilityModel,
    firstName: true,
    lastName: true,
    preferredName: true,
    favorite: config.can(PermissionEnum.SEND_INVITATION),
    city: true,
    state: true,
    localUnion: true,
  };

  if (isUsersLoadingError) {
    return (
      <DashboardWrapper breadcrumbs={[['User Directory']]}>
        <SomethingWentWrongState />
      </DashboardWrapper>
    );
  }

  return (
    <DashboardWrapper breadcrumbs={[['User Directory']]} navigationChildren={users?.length ? <Toolbar options="mini" /> : null}>
      {isUsersLoading ? (
        <Loader />
      ) : users?.length ? (
        <Box component="main" sx={{ height: 'calc(100vh - 100px)', pt: 4 }}>
          <UsersTable
            users={
              config.can(PermissionEnum.VIEW_HIDDEN_ROLES)
                ? users
                : users.filter((user) => !user.roles?.some((role) => role.types.includes('hidden')))
            }
            isEditable={false}
            columnVisibilityModel={columnsVisibility}
            hiddenColumns={{ userRoles: true, email: true, phone: true }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            mt: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            flexDirection: 'column',
          }}
        >
          <Box sx={{ ml: 1 }}>
            <img src={linemanGroup} alt="Empty list" />
          </Box>
          <H1 sx={{ mb: 3 }}>{t('usersPage.noUsers')}</H1>
          <P1R>{t('usersPage.thereNoUsers')}</P1R>
        </Box>
      )}
    </DashboardWrapper>
  );
}
