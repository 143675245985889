import { Box, List, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { themeColors } from 'src/theme';
import { Button, H3, Icon, P2M, P2R } from 'src/components';
import { ClearStatus } from 'src/shared/enums/clearStatus.enum';

export const VerifiedLabel = () => (
  <P2R sx={{ display: 'flex', alignItems: 'center', color: themeColors.green, width: '105px' }}>
    <Icon name="accepted" sx={{ mr: 0.5 }} />
    Verified
  </P2R>
);

export const NotCompleteLabel = () => (
  <P2R sx={{ display: 'flex', alignItems: 'center', color: themeColors.grayPlaceholders, ml: 2 }}>
    <Icon name="exclamationOrange" sx={{ mr: 1 }} />
    Not Complete
  </P2R>
);

export const InReviewLabel = ({ text, sx }: { text: string; sx?: { [key: string]: any } }) => (
  <P2R sx={{ display: 'flex', alignItems: 'center', color: themeColors.blue, ...sx }}>
    <Icon name="pendingBlue" sx={{ mr: 0.5 }} />
    {text}
  </P2R>
);

export const RejectedLabel = () => (
  <P2R sx={{ display: 'flex', alignItems: 'center', color: themeColors.red, width: '105px' }}>
    <Icon name="rejected" sx={{ mr: 0.5 }} />
    Rejected
  </P2R>
);

export const NotCompletedInfo = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
    <Icon name="exclamationOrange" sx={{ mr: 1 }} />
    <P2M>Information is not complete. </P2M>
  </Box>
);

export const UpdateButtons = ({
  disabled,
  loading = false,
  onCancel,
  onUpdate,
}: {
  disabled: boolean;
  loading?: boolean;
  onCancel: () => void;
  onUpdate: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: { xs: 'column', md: 'row' } }}>
      <Button
        primary={false}
        sx={{ textTransform: 'capitalize', mr: '14px', width: { xs: '100%', md: '200px' } }}
        disabled={disabled}
        onClick={onCancel}
      >
        {t('splitRosterPage.cancel')}
      </Button>
      <Button
        sx={{ textTransform: 'capitalize', mt: { xs: '14px', md: 0 }, width: { xs: '100%', md: '200px' } }}
        disabled={disabled}
        onClick={onUpdate}
        loading={loading}
      >
        {t('editVehicle.update')}
      </Button>
    </Box>
  );
};

export const VerifyButtons = ({
  disabled = false,
  loading = false,
  isVerified = false,
  isRejected = false,
  onReject,
  onVerify,
}: {
  disabled?: boolean;
  loading?: boolean;
  isVerified: boolean;
  isRejected: boolean;
  onReject: () => void;
  onVerify: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', flexDirection: { xs: 'column', md: 'row' } }}>
      <Button
        sx={{ textTransform: 'capitalize', mr: '14px', width: { xs: '100%', md: '200px' }, backgroundColor: themeColors.red }}
        disabled={disabled || isRejected}
        onClick={onReject}
      >
        {t('profilePage.reject')}
      </Button>
      <Button
        sx={{ textTransform: 'capitalize', mt: { xs: '14px', md: 0 }, width: { xs: '100%', md: '200px' }, backgroundColor: themeColors.green }}
        disabled={disabled || isVerified}
        onClick={onVerify}
        loading={loading}
      >
        {t('profilePage.verify')}
      </Button>
    </Box>
  );
};

export const OnboardingDetails = ({
  emailConfirmed,
  issues,
  clearStatus,
}: {
  emailConfirmed: boolean;
  issues?: string[];
  clearStatus: ClearStatus;
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: themeColors.grayLight,
        borderRadius: '4px',
        border: `1px solid ${themeColors.grayMedium}`,
        width: { xs: '100%', md: '622px' },
        p: 3,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', pb: 3 }}>
        <H3> {t('profilePage.onboardingDetails')}</H3>
      </Box>
      <P2R sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {emailConfirmed ? <Icon name="accepted" /> : <Icon name="rejected" />}
        {t('profilePage.emailVerified')}
      </P2R>
      <P2R sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
        {clearStatus === ClearStatus.APPROVED && (
          <>
            <Icon name="accepted" />
            {t('profilePage.clearSuccess')}
          </>
        )}
        {clearStatus === ClearStatus.NOT_STARTED ? (
          <>
            <Icon name="exclamationOrange" sx={{ mx: 1 }} />
            {t('profilePage.clearNotStarted')}
          </>
        ) : null}
        {clearStatus === ClearStatus.IN_PROGRESS ? (
          <>
            <Icon name="exclamationBlue" />
            {t('profilePage.clearInProgress')}
          </>
        ) : null}
        {clearStatus === ClearStatus.DECLINED ? (
          <>
            <Icon name="rejected" />
            {t('profilePage.clearFailed')}
          </>
        ) : null}
      </P2R>
      {clearStatus === ClearStatus.DECLINED && issues?.length ? (
        <List sx={{ listStyleType: 'disc', pl: 6 }}>
          {issues?.map((issue) => (
            <ListItem sx={{ display: 'list-item', p: 0 }}>
              <P2R>{issue}</P2R>
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
};
